<template>
  <v-container class="pa-10">
    <v-data-table :headers="headers" :items="contract" sort-by="id" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Product Specification</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1200px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Specification</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.color" label="color"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.size" label="Size"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.unit" label="Unit"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.weight" label="Weight"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.quantity" label="Quantity"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialog = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveEdit">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-container>
            <v-dialog v-model="dialog2" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary float-right"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  mdi-plus
                  v-on="on"
                >
                  <v-icon color="white">mdi-plus</v-icon>Add Specification
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">New Specification</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="color" label="color"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="size" label="Size"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="unit" label="Unit"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="weight" label="Weight"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="quantity" label="Quantity"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="dialog2 = false">Cancel</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addNewSpecification"
                  >Add New Specification</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    unit: "",
    size: "",
    color: "",
    quantity: "",
    weight: "",

    headers: [
      { text: "Size", value: "size" },
      { text: "Color", value: "color" },
      { text: "Weight", value: "weight" },
      { text: "Quantity", value: "quantity" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    contract: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      unit: "",
      size: "",
      color: "",
      quantity: "",
      weight: "",
    },
    defaultItem: {
      unit: "",
      size: "",
      color: "",
      quantity: "",
      weight: "",
    },
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
  },
  methods: {
    initialize() {
      axios
        .get(`productdetails/showspec/${this.$route.query.id}/`)
        .then((response) => {
    
          this.contract = response.data;
        });
    },

    addNewSpecification() {

      let specification = {
        unit: this.unit,
        size: this.size,
        color: this.color,
        quantity: this.quantity,
        weight: this.weight,
      };

      axios
        .post(`productdetails/add_specification/${this.$route.query.id}/`, specification)
        .then((response) => {
          this.contract.push(specification);
        });

      this.dialog2 = false
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios
          .post(`productdetails/deletespec/${item.id}/`)
          .then((response) => {
            this.contract = this.contract.filter(
              (contact) => contact.id !== item.id
            );
          });
      }
    },

    close() {
      this.dialog = false;
      this.dialog2 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveEdit() {
      axios
        .post(
          `productdetails/edit_specification/${this.editedItem.id}/`,
          this.editedItem
        )
        .then((response) => {
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
